import React from "react"
import Layout from "../components/layout"
import SEO from "../components/implementation/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import HeroBanner from "../components/implementation/index"
import Form from "../components/implementation/contactForm/index"

const Implementation = () => {
  // Data
  const { sanityFinanceOptionsPage: data } = useStaticQuery(graphql`
    query ImplementationOptionsPage {
      sanityFinanceOptionsPage {
        title
        metaDescription
      }
    }
  `)
  return (
    <Layout>
      <SEO />
      <HeroBanner />
      <Form />
    </Layout>
  )
}

export default Implementation
