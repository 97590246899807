import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../components/seo"

const ImplementationSEO = () => {
  const {
    sanityImplementationPage: { seo: data },
  } = useStaticQuery(graphql`
    query implementationSEO {
      sanityImplementationPage {
        seo {
          pageTitle
          pageKeyWords
          pageDescription
          ogImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)

  let ogImage = data.ogImage
  if (ogImage) {
    ogImage = data.ogImage.asset.url
  } else {
    ogImage = null
  }

  return (
    <SEO
      title={data.pageTitle}
      description={data.pageDescription}
      keywords={data.pageKeyWords}
      image={ogImage}
      url="/implementation"
    />
  )
}

export default ImplementationSEO
